import React, {
  useCallback, useEffect, useRef, useState
} from 'react';

import { Check } from 'lucide-react';

const CELL_SIZE = 20;
const SPEED = 200;
const MESSAGE = 'MISLEAD';

const claimsComparison = [
  {
    claim: 'Pay anyone, anywhere in minutes.',
    truth: 'Can take days to run global payroll.',
  },
  {
    claim: 'We take on all of the responsibility to make sure you’re compliant with local laws.',
    truth: 'Users report they run into payroll compliance issues across TrustRadius and G2.',
  },
  {
    claim: 'Onboard new hires in minutes through our platform.',
    truth: 'Onboarding new hires can take several days to process and approve in countries like Brazil and China and more.',
  },
  {
    claim: 'Customize access levels depending on what data you want each person or team to be able to see.',
    truth: 'Not even managers can have customized permissions settings on Deel.',
  },
  {
    claim: 'No third-parties are involved in handling sensitive employee information.',
    truth: 'Relies on many third parties to support solutions like Contractor Management, Ben Admin, Background Checks, PEO, and more.',
  },
  {
    claim: 'Automate anything for your workflow.',
    truth: 'Limited to 3 automated actions only: send email, send slack message, create task.',
  },
  {
    claim: 'Customize reports to make them more digestible for stakeholders and analyze spending in specific areas, like social security, 401k, pensions, and more.',
    truth: 'Customizable but no ability to share one-time reports.',
  },
];

const wrapPosition = (pos, size) => {
  if (pos < 0) {
    return size - 1;
  }
  if (pos >= size) {
    return 0;
  }

  return pos;
};

const checkCollision = (head, snakeBody) => {
  return snakeBody.some((segment, index) =>
    index !== 0 && segment.x === head.x && segment.y === head.y);
};

const SnakeGame = () => {
  const [gridSize, setGridSize] = useState(20);
  const [snake, setSnake] = useState([
    {
      x: 5,
      y: 5,
    },
  ]);
  const [direction, setDirection] = useState('RIGHT');
  const [food, setFood] = useState({
    x: 15,
    y: 5,
  });
  const [falseClaims, setFalseClaims] = useState(0);
  const [touchStart, setTouchStart] = useState(null);
  const [gameOver, setGameOver] = useState(false);
  const [collectedClaims, setCollectedClaims] = useState([]);
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [levelComplete, setLevelComplete] = useState(false);
  const [playing, setPlaying] = useState(false);
  const gameBoardRef = useRef(null);

  const claimsList = claimsComparison.map((claim) => claim.claim);

  const resetGame = () => {
    setSnake([
      {
        x: 5,
        y: 5,
      },
    ]);
    setDirection('RIGHT');
    setFood(getRandomPosition());
    setFalseClaims(0);
    setGameOver(false);
    setCollectedClaims([]);
    setCurrentMessageIndex(0);
    setLevelComplete(false);
  };

  useEffect(() => {
    const prevWidth = window.innerWidth;

    const setGrid = () => {
      const base = window.innerWidth > 500 ? 400 : 300;
      const smallerDimension = Math.min(window.innerWidth - 40, base);
      const newGridSize = Math.floor(smallerDimension / CELL_SIZE);
      setGridSize(newGridSize);
      setFood(getRandomPosition(newGridSize));
    };

    const updateGridSize = () => {
      if (prevWidth !== window.innerWidth) {
        setGrid();
      }
    };

    setGrid();

    window.addEventListener('resize', updateGridSize);

    return () => window.removeEventListener('resize', updateGridSize);
  }, []);

  useEffect(() => {
    if (collectedClaims.length === claimsList.length && currentMessageIndex === MESSAGE.length) {
      setLevelComplete(true);
    }
  }, [
    collectedClaims,
    currentMessageIndex,
    claimsList.length,
  ]);

  const getRandomPosition = (size = gridSize) => ({
    x: Math.floor(Math.random() * (size - 2)) + 1,
    y: Math.floor(Math.random() * (size - 2)) + 1,
  });

  const handleTouchStart = (e) => {
    const touch = e.touches[0];

    if (!playing) {
      setPlaying(true);
    }

    setTouchStart({
      x: touch.clientX,
      y: touch.clientY,
    });
  };

  const handleTouchMove = (e) => {
    if (!touchStart) {
      return;
    }

    e.preventDefault();

    const touch = e.touches[0];
    const deltaX = touch.clientX - touchStart.x;
    const deltaY = touch.clientY - touchStart.y;

    if (Math.abs(deltaX) > Math.abs(deltaY)) {
      setDirection(deltaX > 0 ? 'RIGHT' : 'LEFT');
    } else {
      setDirection(deltaY > 0 ? 'DOWN' : 'UP');
    }

    if (!playing) {
      setPlaying(true);
    }

    setTouchStart({
      x: touch.clientX,
      y: touch.clientY,
    });
  };

  const moveSnake = useCallback(() => {
    if (gameOver) {
      return;
    }

    setSnake((prevSnake) => {
      const newSnake = [...prevSnake];
      const head = { ...newSnake[0] };

      switch (direction) {
        case 'UP': { head.y -= 1; break;
        }
        case 'DOWN': { head.y += 1; break;
        }
        case 'LEFT': { head.x -= 1; break;
        }
        case 'RIGHT': { head.x += 1; break;
        }
        default: { break;
        }
      }

      head.x = wrapPosition(head.x, gridSize);
      head.y = wrapPosition(head.y, gridSize);

      if (checkCollision(head, newSnake)) {
        setGameOver(true);

        return prevSnake;
      }

      if (head.x === food.x && head.y === food.y) {
        const claim = claimsList[falseClaims % claimsList.length];
        if (!collectedClaims.includes(claim)) {
          setCollectedClaims((prev) => [...prev, claim]);
        }
        setFalseClaims((prev) => prev + 1);
        if (currentMessageIndex < MESSAGE.length) {
          setCurrentMessageIndex((prev) => prev + 1);
        }
        setFood(getRandomPosition());
      } else {
        newSnake.pop();
      }

      newSnake.unshift(head);

      return newSnake;
    });
  }, [
    direction,
    food,
    gridSize,
    gameOver,
    falseClaims,
    currentMessageIndex,
    claimsList,
  ]);

  useEffect(() => {
    const oppositeDirection = {
      DOWN: 'UP',
      LEFT: 'RIGHT',
      RIGHT: 'LEFT',
      UP: 'DOWN',
    };

    function handleKeyPress(e) {
      const directionMap = {
        ArrowDown: 'DOWN',
        ArrowLeft: 'LEFT',
        ArrowRight: 'RIGHT',
        ArrowUp: 'UP',
      };

      const newDirection = directionMap[e.key];
      if (newDirection) {
        e.preventDefault();
        setDirection((prev) => {
          if (prev === oppositeDirection[newDirection]) {
            return prev;
          }

          return newDirection;
        });
      }
    }

    const gameBoardElement = gameBoardRef.current;
    if (gameBoardElement) {
      gameBoardElement.addEventListener('keydown', handleKeyPress);
    }

    return () => {
      if (gameBoardElement) {
        gameBoardElement.removeEventListener('keydown', handleKeyPress);
      }
    };
  }, []);

  useEffect(() => {
    if (playing) {
      gameBoardRef.current.focus();
    }
  }, [playing]);

  useEffect(() => {
    const gameLoop = setInterval(moveSnake, SPEED);

    return () => clearInterval(gameLoop);
  }, [moveSnake]);

  return (
    <>

      <div className="bg-plum max-w-full pt-160 pb-120 px-16">
        <div className="container mx-auto max-w-700 md:max-w-1400 mx-auto flex flex-col md:flex-row p-4 h-auto" style={{ gap: '60px' }}>

          <div>

            <h1 className="mb-12 text-center text-white md:mb-16 md:text-left" style={{ minWidth: 'max-content' }}><span className="jimbo text-yellow">Don't Buy</span> Snake Oil</h1>

            <p className="is-style-p1 max-w-500 mb-16 text-left text-white opacity-70 text-center mx-auto md:text-left md:mx-initial">Deel often claims to be a one-stop solution for all your global payroll needs, but their customers pay the price for gaps beneath the surface.</p>
            <p className="is-style-p1 max-w-500 mb-16 text-left text-white opacity-70 text-center mx-auto md:text-left md:mx-initial">Play this game to find the differences between Deel’s claims and the reality of their product. </p>

            <div className="bg-plum-800 p-24 shadow-lg flex flex-col items-center" style={{ borderRadius: '8px' }}>

              <div className="flex-grow w-full mb-4 overflow-hidden relative">

                <div className="flex flex-col items-center">
                  <div className="mb-4 w-full">
                    <p className="text-xl font-bold text-center text-white">Deel's misleading claims: {falseClaims}</p>
                    <p className="text-lg font-bold text-center mt-2 text-white">
                      <span style={{ color: '#20968F' }}>{MESSAGE.slice(0, currentMessageIndex)}</span>
                      <span className="text-gray-300">{MESSAGE.slice(currentMessageIndex)}</span>
                    </p>
                    <p className="text-sm text-white text-center mt-1">
                      {MESSAGE.length - currentMessageIndex} letters remaining
                    </p>
                  </div>

                  <div className="p-12 text-sm text-white text-center">
                    Use arrow keys or swipe to control the snake
                  </div>

                  <div
                    ref={gameBoardRef}
                    className="relative touch-none bg-tertiary-gray1 mt-8"
                    onClick={() => setPlaying(true)}
                    onTouchMove={handleTouchMove}
                    onTouchStart={handleTouchStart}
                    style={{
                      height: gridSize * CELL_SIZE,
                      touchAction: 'none',
                      width: gridSize * CELL_SIZE,
                    }}
                    tabIndex={99}
                  >

                    {levelComplete && (
                      <div
                        className="text-center z-50 bg-white bg-opacity-80 p-12"
                        style={{
                          backgroundColor: 'rgba(255, 255, 255, 0.8)',
                          left: '50%',
                          padding: '12px',
                          position: 'absolute',
                          textAlign: 'center',
                          top: '50%',
                          transform: 'translate(-50%, -50%)',
                          zIndex: 999,
                        }}
                      >
                        <h4 className="text-lg font-bold" style={{ color: '#20968F' }}>Level Up!</h4>
                        <p className="mt-2">Want to see alternatives to snake oil?</p>
                        <a
                          className="btn is-style-primary py-8 px-20 mt-12"
                          href="/request-demo"
                          id="snake-game-level-up-button"
                        >
                          Click here to see a demo
                        </a>
                      </div>
                    )}

                    {gameOver && (
                      <div
                        className="mt-6 p-4 bg-red-100 rounded-lg"
                        style={{
                          backgroundColor: 'rgba(255, 255, 255, 0.8)',
                          left: '50%',
                          padding: '12px',
                          position: 'absolute',
                          textAlign: 'center',
                          top: '50%',
                          transform: 'translate(-50%, -50%)',
                          zIndex: 999,
                        }}
                      >
                        <h4 className="text-lg font-bold text-red-800">Game Over</h4>
                        <p className="mt-2">Don't buy snake oil</p>
                        <button
                          className="btn is-style-primary py-8 px-20 mt-12"
                          onClick={resetGame}
                        >
                          Try Again
                        </button>
                      </div>
                    )}

                    {!playing && (
                      <div
                        className="text-center z-50 bg-white bg-opacity-80 p-12"
                        style={{
                          backgroundColor: 'rgba(255, 255, 255, 0.8)',
                          left: '50%',
                          padding: '12px',
                          position: 'absolute',
                          textAlign: 'center',
                          top: '50%',
                          transform: 'translate(-50%, -50%)',
                          zIndex: 999,
                        }}
                      >
                        {/* <h4 className="text-lg font-bold" style={{ color: '#20968F' }}>Play Here</h4> */}
                        <p className="mt-2 font-bold" style={{ color: '#20968F' }}>Click or tap to play</p>
                        <button
                          className="btn is-style-primary py-8 px-20 mt-12"
                          onClick={() => setPlaying(true)}
                        >
                          Play here
                        </button>
                      </div>
                    )}

                    {snake.map((segment, index) => (
                      <div
                        key={index}
                        className="absolute rounded-sm"
                        style={{
                          alignItems: 'center',
                          backgroundColor: '#C4B1F9',
                          borderRadius: '2px',
                          display: 'flex',
                          height: CELL_SIZE - 2,
                          justifyContent: 'center',
                          left: segment.x * CELL_SIZE,
                          top: segment.y * CELL_SIZE,
                          width: CELL_SIZE - 2,
                        }}
                      >
                        {index === 0 && (
                          <span className="absolute text-light-blue-400 text-xs">
                            {MESSAGE[currentMessageIndex] || 'D'}
                          </span>
                        )}
                      </div>
                    ))}

                    {!levelComplete && playing && (
                      <div
                        className="absolute"
                        style={{
                          left: food.x * CELL_SIZE,
                          top: food.y * CELL_SIZE,
                        }}
                      >
                        <Check className="text-gray-900" size={18} />
                        <div
                          className="absolute bg-white p-8 text-xs shadow-lg whitespace-normal ml-4"
                          style={{
                            backgroundColor: 'rgba(255,255,255,0.8)',
                            borderRadius: '4px',
                            left: food.x > gridSize * 0.7 ? 'auto' : '100%',
                            maxWidth: `${gridSize * CELL_SIZE * 0.8}px`,
                            right: food.x > gridSize * 0.7 ? '100%' : 'auto',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            width: '150px',
                            zIndex: 10,
                          }}
                        >
                          {claimsList[falseClaims % claimsList.length]}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="w-full flex flex-col items-center mt-16">

                <div className="flex items-center mb-4 w-full justify-between px-32">
                  <div className="flex flex-col items-center">
                    <button className="bg-plum text-gray-600 font-bold py-3 px-6 mb-1 rounded" onClick={() => setDirection('UP')}>▲</button>
                    <div className="flex">
                      <button className="bg-plum text-gray-600 font-bold py-3 px-6 rounded mr-1" onClick={() => setDirection('LEFT')}>◀</button>
                      <button className="bg-plum text-gray-600 font-bold py-3 px-6 rounded ml-1" onClick={() => setDirection('RIGHT')}>▶</button>
                    </div>
                    <button className="bg-plum text-gray-600 font-bold py-3 px-6 mt-1 rounded" onClick={() => setDirection('DOWN')}>▼</button>
                  </div>

                  <div className="flex flex-col gap-4">
                    <button
                      className="bg-plum text-gray-600 font-bold mb-2 ml-20"
                      style={{
                        borderRadius: '100px',
                        height: '36px',
                        width: '36px',
                      }}
                    >A</button>
                    <button
                      className="bg-plum text-gray-600 font-bold mr-20"
                      style={{
                        borderRadius: '100px',
                        height: '36px',
                        width: '36px',
                      }}
                    >B</button>
                  </div>
                </div>

              </div>
            </div>

          </div>

          <div className="flex-1 flex-grow bg-white p-24 shadow-md rounded-8 md:mt-24 overflow-hidden">
            {collectedClaims.length === 0 && (
              <span
                style={{
                  alignItems: 'center',
                  backgroundColor: 'rgba(64, 37, 48, 0.8)',
                  color: 'white',

                  display: 'flex',
                  fontSize: '2rem',
                  fontWeight: 'bold',

                  height: '100%',

                  justifyContent: 'center',

                  left: 0,

                  // opacity: 0.7,
                  position: 'absolute',
                  textAlign: 'center',
                  top: 0,
                  width: '100%',
                  zIndex: 9,
                }}
              >
                <span style={{ marginTop: '-20%' }}>Play the game to discover misleading claims</span>
              </span>
            )}
            {/* <h3 className="text-lg font-bold mb-4">Claims Comparison</h3> */}
            <div className="overflow-hidden">
              <div className="min-w-full divide-y divide-gray-200 mt-16">
                <div className="bg-gray-50 grid-cols-2 gap-4" style={{ display: 'grid' }}>
                  <div className="py-3 text-left font-medium text-gray-500 uppercase tracking-wider flex-1" style={{ fontSize: 'clamp(0.75rem, 0.6786rem + 0.3571vw, 1rem)' }}>
                    Deel's Marketing Claim
                  </div>
                  <div className="py-3 text-left font-medium text-gray-500 uppercase tracking-wider flex-1" style={{ fontSize: 'clamp(0.75rem, 0.6786rem + 0.3571vw, 1rem)' }}>
                    Reality
                  </div>
                </div>

                {claimsComparison && claimsComparison.map(({ claim, truth }, index) => {
                  const bgColor = index % 2 === 0 ? 'bg-tertiary-gray1' : 'bg-white';

                  const isBlurred = !collectedClaims.includes(claim);

                  return (
                    <div
                      key={index}
                      className={`${bgColor} w-full p-24 rounded-8 grid-cols-2 gap-16`}
                      style={{
                        display: 'grid',
                        filter: isBlurred ? 'blur(3px) grayscale(100%)' : 'none',
                        transform: isBlurred ? 'scale(0.97)' : 'none',
                        transition: '250ms',
                      }}
                    >
                      <div className="py-3 text-sm text-red-600 flex-1">
                        <div className="flex flex-row items-start gap-8">
                          <div className="imagex h-24 w-24">
                            <img
                              className="h-24 w-24"
                              loading="lazy"
                              src="https://rippling.imgix.net/images/Close-circle.svg"
                              style={{
                                minHeight: '24px',
                                minWidth: '24px',
                              }}
                            />
                          </div>
                          "{claim}"
                        </div>
                      </div>
                      <div className="px-4 py-3 text-sm text-green-600 flex-1">
                        <div className="flex flex-row items-start gap-8">
                          <div className="imagex h-24 w-24 ">
                            <img
                              className="h-24 w-24"
                              loading="lazy"
                              src="https://rippling.imgix.net/images/icon-check-circle-filled-yellow.svg"
                              style={{
                                minHeight: '24px',
                                minWidth: '24px',
                              }}
                            />
                          </div>
                          {truth}
                        </div>
                      </div>
                    </div>
                  );
                })}

              </div>

            </div>

          </div>
        </div>
      </div>

      <div className="wrapper bg-plum-800 px-16 pt-64 pb-64 sm:pt-120 sm:pb-120 ">
        <div className="grid  ">
          <div className="row w-100% ">
            <div className="column w-100% text-white text-center ">
              <h2 className="mb-16 md:mb-12 sm:mb-16 h1 text-48 leading-52 -tracking-1 md:text-52">
                Tired of Playing Games?
              </h2>
              <p className="is-style-p1 text-plum-200 sm:mb-48 max-w-550 ml-auto mr-auto mb-26">See Rippling in Action</p>

              <div className="wrapper flex justify-center flex-col sm:flex-row items-center max-w-750 mx-auto ">
                <a
                  className="btn btn is-style-primary mb-16 w pb-18 pt-18 px-16 sm:mb-0 sm:mr-20 min-w-200 md:w-50% w-100%"
                  href="/request-demo"
                  id="snake-game-cta-button"
                >
                  <span>Request demo</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SnakeGame;
